<template>
  <!--
    Used to manage the contact roles
  -->
  <div class="contactRolesList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(contactRoles)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(contactRoles)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="textFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('textFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="checkboxTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <input
              :checked="kgm_getNestedValue(props.field, props.dataItem)"
              type="checkbox"
              disabled
              readonly
            >
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>Edit</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <ContactRolesAdd
          v-if="addingElement"
          @reload="reloadContactRoles"
        />
        <ContactRolesEdit
          v-else
          :contact-role="contactRole"
          @reload="reloadContactRoles"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="contactRole">
        {{ $t('sureToDelete', {'0' : contactRole.name}) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeContactRole()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import ContactRolesEdit from '@/components/Contacts/ContactRolesEdit.vue';
import ContactRolesAdd from '@/components/Contacts/ContactRolesAdd.vue';

export default {
  name: "ContactRolesList",
  components: {
    SweetModal,
    Sidebar,
    ContactRolesEdit,
    ContactRolesAdd
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      contactRoles: null,
      contactRole: null,
      showSidebar: false,
      addingElement: false,
      kgm_columns: [
        {
          field: 'id',
          filterable: false,
          title: 'Id',
        },
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "textFilter"
        },
        {
          field: 'allowMultipleCustomers',
          filterable: true,
          title: 'Allow Multiple Customers',
          filter: "boolean",
          cell: 'checkboxTemplate'
        },
        {
          filterable: false,
          title: this.$t('options'),
          cell: 'optionsTemplate'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('menu.contactRoles')
    }
  },
  created () {
    this.getContactRoles();
  },
  methods: {
    //#region Sidebar
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.showSidebar = true;
    },
    showSidebarEdit (role) {
      this.contactRole = role;
      this.addingElement = false;
      this.showSidebar = true;
    },
    //#endregion

    //#region API-calls
    reloadContactRoles () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getContactRoles();
    },
    openConfirmModal (role) {
      this.contactRole = role;
      this.$refs.confirmDelete.open();
    },
    removeContactRole () {
      this.axios.delete(`/Contact/ContactRole?id=${this.contactRole.id}`, )
        .then(() => {
          this.$snotify.success(this.$t('contacts.contactRoleDeletedSuccessfully'));
          this.reloadContactRoles();
        })
        .finally(() => {
          this.$refs.confirmDelete.close();
        });
    },
    getContactRoles () {
      this.loading = true;
      this.axios.get('/Contact/ContactRoles')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contactRoles = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //#endregion
  }
}
</script>
<style>
.contactRolesList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>