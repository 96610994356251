<template>
  <!--
    Used to edit a contact Role
  -->
  <div class="contactRoleEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('contactRole') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>Name</label>
        <input
          v-model="contactRoleDto.name"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>Allow Multiple Customers</label>
        <input
          v-model="contactRoleDto.allowMultipleCustomers"
          class="ml-3 mt-1"
          type="checkbox"
        >
      </div>
      <button 
        class="btn btn-primary float-right" 
        :disabled="!contactRoleDto.name"
        @click.prevent="editContactRole()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('save') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
export default {
  name: "ContactRolesEdit",
  props: {
    contactRole: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      contactRoleDto: {}
    }
  },
  created () {
    this.contactRoleDto = JSON.parse(JSON.stringify(this.contactRole))
  },
  methods: {
    async editContactRole () {
      await this.axios.put(`/Contact/ContactRole`, this.contactRoleDto)
        .then(() => { 
          this.$snotify.success(this.$t('contacts.contactRoleUpdatedSuccessfully'));       
          this.$emit("reload");
        })
    }
  }
}
</script>
<style scoped>
.contactRoleAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
