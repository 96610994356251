<template>
  <!--
    Used to create a contact Role
  -->
  <div class="contactRoleAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('contactRole') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>Name</label>
        <input
          v-model="contactRole.name"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>Allow Multiple Customers</label>
        <input
          v-model="contactRole.allowMultipleCustomers"
          class="ml-3 mt-1"
          type="checkbox"
        >
      </div>
      <button 
        class="btn btn-primary float-right" 
        :disabled="!contactRole.name"
        @click.prevent="addContactRole()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
export default {
  name: "ContactRolesAdd",
  data () {
    return {
      contactRole: {}
    }
  },
  methods: {
    async addContactRole () {
      await this.axios.post(`/Contact/ContactRole`, this.contactRole)
        .then(() => { 
          this.$snotify.success(this.$t('contacts.contactRoleAddedSuccessfully'));       
          this.$emit("reload");
        })
    }
  }
}
</script>
<style scoped>
.contactRoleAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
